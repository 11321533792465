import { createToken, Lexer, CstParser, tokenMatcher } from "chevrotain";
import _ from "lodash";

const keywordToken = createToken({
    name: "keyword",
    pattern: Lexer.NA
});
const fieldToken = createToken({
    name: "fieldToken",
    pattern: Lexer.NA
});

const braceOpenToken = createToken({
    name: "braceOpenToken",
    label: "(",
    pattern: /\(/,
    categories: keywordToken
});
const braceCloseToken = createToken({
    name: "braceCloseToken",
    label: ")",
    pattern: /\)/,
    categories: keywordToken
});
const boolAnd = createToken({
    name: "boolAnd",
    label: "and",
    pattern: /and/,
    categories: keywordToken
});
const boolOr = createToken({
    name: "boolOr",
    label: "or",
    pattern: /or/,
    categories: keywordToken
});
const opNotToken = createToken({
    name: "opNotToken",
    label: "! (not)",
    pattern: /!/,
    categories: keywordToken
});
const opLessEqual = createToken({
    name: "opLessEqual",
    label: "<=",
    pattern: /<=/,
    categories: keywordToken
});
const opLess = createToken({
    name: "opLess",
    label: "<",
    pattern: /</,
    categories: keywordToken
});
const opGreaterEqual = createToken({
    name: "opGreaterEqual",
    label: ">=",
    pattern: />=/,
    categories: keywordToken
});
const opNotEqual = createToken({
    name: "opNotEqual",
    label: "!=",
    pattern: /!=/,
    categories: keywordToken
});
const opGreater = createToken({
    name: "opGreater",
    label: ">",
    pattern: />/,
    categories: keywordToken
});
const opEqual = createToken({
    name: "opEqual",
    label: "=",
    pattern: /=/,
    categories: keywordToken
});
const valueToken = createToken({
    name: "valueToken",
    label: '"text"',
    pattern: /"([^"]*)"/
});
const flagToken = createToken({
    name: "flagToken",
    pattern: /[a-zA-Z_0-9]+/,
    categories: fieldToken
});
const field = createToken({
    name: "field",
    pattern: /[a-zA-Z_0-9]+:[a-zA-Z_0-9]+/,
    categories: fieldToken
});
const whiteSpace = createToken({
    name: "whiteSpace",
    pattern: /\s+/,
    group: Lexer.SKIPPED
});
const lastResortToken = createToken({
    name: "lastResortToken",
    pattern: /.+/
});

const allTokens = [
    whiteSpace,
    braceOpenToken,
    braceCloseToken,
    boolAnd,
    boolOr,
    field,
    flagToken,
    valueToken,
    opLessEqual,
    opGreaterEqual,
    opLess,
    opGreater,
    opNotEqual,
    opEqual,
    opNotToken,
    lastResortToken
];
let statementsLexer = new Lexer(allTokens);

class MyParser extends CstParser {
    constructor() {
        super(allTokens);

        let $ = this;

        $.RULE("startRule", () => {
            $.OPTION(() => {
                $.SUBRULE($.multipart);
            });
        });

        $.RULE("multipart", () => {
            $.SUBRULE($.multipartinner);
            $.MANY(() => {
                // "and", "or"
                $.SUBRULE($.multipartbool);
            });
        });

        $.RULE("multipartbool", () => {
            $.SUBRULE($.boolop);
            $.SUBRULE($.multipart);
        });

        $.RULE("boolop", () => {
            $.OR([{ ALT: () => $.CONSUME(boolAnd) }, { ALT: () => $.CONSUME(boolOr) }]);
        });

        $.RULE("multipartinner", () => {
            $.OR([{ ALT: () => $.SUBRULE($.comparison) }, { ALT: () => $.SUBRULE($.flagstmt) }, { ALT: () => $.SUBRULE($.notflag) }, { ALT: () => $.SUBRULE($.braced) }]);
        });

        $.RULE("flagstmt", () => {
            $.CONSUME(flagToken);
        });

        $.RULE("notflag", () => {
            $.CONSUME(opNotToken);
            $.CONSUME(flagToken);
        });

        $.RULE("comparison", () => {
            $.SUBRULE($.fieldvalue);
            $.SUBRULE($.operator);
            $.SUBRULE1($.fieldvalue);
        });

        $.RULE("operator", () => {
            $.OR([
                { ALT: () => $.CONSUME(opLess) },
                { ALT: () => $.CONSUME(opLessEqual) },
                { ALT: () => $.CONSUME(opEqual) },
                { ALT: () => $.CONSUME(opNotEqual) },
                { ALT: () => $.CONSUME(opGreaterEqual) },
                { ALT: () => $.CONSUME(opGreater) }
            ]);
        });

        $.RULE("fieldvalue", () => {
            $.OR([{ ALT: () => $.CONSUME(field) }, { ALT: () => $.CONSUME(valueToken) }]);
        });

        $.RULE("braced", () => {
            $.CONSUME(braceOpenToken);
            $.SUBRULE($.multipart);
            $.CONSUME(braceCloseToken);
        });

        this.performSelfAnalysis();
    }
}

let parserInstance = new MyParser();

export default {
    checkValid(querytext, fields, flags) {
        const lexResult = statementsLexer.tokenize(querytext);
        if (lexResult.errors.length > 0) {
            return false;
        }

        parserInstance.input = lexResult.tokens;
        parserInstance.startRule();

        if (parserInstance.errors.length > 0) {
            return false;
        } else {
            // Now checkk all "field" and "flag" tokens to be valid
            for (var t = 0; t < lexResult.tokens.length; t++) {
                if (tokenMatcher(lexResult.tokens[t], fieldToken)) {
                    if (lexResult.tokens[t].tokenType.name === "flagToken") {
                        // Check flag
                        let match = false;
                        for (let flag of flags) {
                            if (flag.id === lexResult.tokens[t].image) {
                                match = true;
                                break;
                            }
                        }

                        if (!match) {
                            return false;
                        }
                    } else {
                        // Check field
                        let match = false;
                        for (let gid in fields) {
                            for (let field of fields[gid].fields) {
                                if (field.id === lexResult.tokens[t].image) {
                                    match = true;
                                    break;
                                }
                            }
                            if (match) {
                                break;
                            }
                        }

                        if (!match) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    },

    getContentAssistSuggestions(cursorpos, querytext, fields, flags) {
        let text = querytext.substring(0, cursorpos);
        const lexResult = statementsLexer.tokenize(text);
        if (lexResult.errors.length > 0) {
            return [];
        }

        const lastInputToken = _.last(lexResult.tokens);
        const secondToLastInputToken = lexResult.tokens[lexResult.tokens.length - 2];
        let partialSuggestionMode = false;
        let assistanceTokenVector = lexResult.tokens;
        let compareImage = "";

        // we have requested assistance while inside a Keyword or field
        if (
            lastInputToken !== undefined &&
            (tokenMatcher(lastInputToken, fieldToken) || tokenMatcher(lastInputToken, keywordToken) || tokenMatcher(lastInputToken, lastResortToken)) &&
            /[^\s]/.test(text[text.length - 1]) &&
            !tokenMatcher(lastInputToken, opNotToken) &&
            !tokenMatcher(lastInputToken, braceOpenToken) &&
            !tokenMatcher(lastInputToken, braceCloseToken)
        ) {
            // Check whether the ":" was entered after field group
            if (secondToLastInputToken !== undefined && tokenMatcher(secondToLastInputToken, flagToken) && tokenMatcher(lastInputToken, lastResortToken) && lastInputToken.image === ":") {
                // Remove both to get all suggestions again
                assistanceTokenVector = _.dropRight(assistanceTokenVector);
                assistanceTokenVector = _.dropRight(assistanceTokenVector);
                partialSuggestionMode = true;
                compareImage = secondToLastInputToken.image + ":";
            } else {
                assistanceTokenVector = _.dropRight(assistanceTokenVector);
                partialSuggestionMode = true;
                compareImage = lastInputToken.image;
            }
        }

        const syntacticSuggestions = parserInstance.computeContentAssist("startRule", assistanceTokenVector);

        let finalSuggestions = {};

        for (let i = 0; i < syntacticSuggestions.length; i++) {
            const currSyntaxSuggestion = syntacticSuggestions[i];
            const currTokenType = currSyntaxSuggestion.nextTokenType;
            const currRuleStack = currSyntaxSuggestion.ruleStack;
            const lastRuleName = _.last(currRuleStack);

            // easy case where a operator is suggested.
            if (currTokenType === valueToken) {
                // add text suggestion
                if (finalSuggestions["value"] === undefined) {
                    finalSuggestions["value"] = { name: "value", label: "Wert", fields: [] };
                }
                finalSuggestions["value"].fields.push({ id: currTokenType.LABEL, label: currTokenType.LABEL });
            } else if (keywordToken.categoryMatchesMap[currTokenType.tokenTypeIdx]) {
                if (finalSuggestions["op"] === undefined) {
                    finalSuggestions["op"] = { name: "op", label: "Op", fields: [] };
                }
                finalSuggestions["op"].fields.push({ id: currTokenType.LABEL, label: currTokenType.LABEL });
            } else if (fieldToken.categoryMatchesMap[currTokenType.tokenTypeIdx]) {
                // in declarations, should not provide content assist for new symbols (Identifiers)
                if (_.includes(["notflag", "flagstmt"], lastRuleName)) {
                    // !flag, flag
                    if (finalSuggestions["flag"] === undefined) {
                        finalSuggestions["flag"] = { name: "flag", label: "Flag", fields: [] };
                    }
                    finalSuggestions["flag"].fields = finalSuggestions["flag"].fields.concat(flags);
                } else if (lastRuleName === "fieldvalue") {
                    // field is requested
                    for (let gid in fields) {
                        finalSuggestions[gid] = {
                            name: "" + fields[gid].name,
                            label: "" + fields[gid].label,
                            fields: []
                        };
                        for (let f = 0; f < fields[gid].fields.length; f++) {
                            finalSuggestions[gid].fields.push(fields[gid].fields[f]);
                        }
                    }
                } else {
                    return [];
                }
            } else {
                return [];
            }
        }

        // we could have duplication because each suggestion also includes a Path, and the same Token may appear in multiple suggested paths.
        for (let gid in finalSuggestions) {
            // Make entries unique
            finalSuggestions[gid].fields = _.uniqBy(finalSuggestions[gid].fields, "id");
        }

        // throw away any suggestion that is not a suffix of the last partialToken.
        if (partialSuggestionMode) {
            for (let gid in finalSuggestions) {
                // Filter entries
                finalSuggestions[gid].fields = _.filter(finalSuggestions[gid].fields, currSuggestion => {
                    return _.startsWith(currSuggestion.id, compareImage);
                });

                // Remove empty groups
                if (finalSuggestions[gid].fields.length === 0) {
                    delete finalSuggestions[gid];
                }
            }
        }

        // Only load suggestions if:
        // - 1. cursor is at position 0 and first token is whitespace
        // - 2. cursor is at end of line
        // - 3. cursor is in between two whitespace tokens
        // - 4. cursor is at end of token with a whitespace afterwards
        if (cursorpos === 0 && (lexResult.tokens.length === 0 || lexResult.tokens[0].tokenType.name === "whiteSpace")) {
            // OK, case 1
            return finalSuggestions;
        } else if (querytext.length === cursorpos) {
            // OK, case 2
            return finalSuggestions;
        } else if (querytext[cursorpos] === " ") {
            // OK, case 3 or 4
            // Explanation: Cursor is right before whitespace, i.e. it is either at end of other token, or after whiteSpace
            //     but it ist definitely before a whiteSpace
            return finalSuggestions;
        } else {
            return [];
        }
    },

    selectField(querytext, field, cursorpos) {
        let cursorOffset = 0;

        // replace "! (not)" with "!"
        if (field === "! (not)") {
            field = "!";
        }

        // Check whether to add some space
        if (querytext[cursorpos] === " ") {
            // Do not add space
            if (field === '"text"') {
                field = '""';
                cursorOffset = -1;
            } else if (field !== "!") {
                // Add offset
                cursorOffset = 1;
            }
        } else {
            // Add space
            if (field === '"text"') {
                field = '"" ';
                cursorOffset = -2;
            } else if (field !== "!") {
                // Add some whitespace
                field = field + " ";
            }
        }

        if (cursorpos === 0) {
            // Insert at position "0"
            querytext = field + querytext;
            return [querytext, field.length + cursorOffset];
        }

        // Let lexer run to get start offset of token at which the cursor is
        const lexResult = statementsLexer.tokenize(querytext);
        if (lexResult.errors.length > 0) {
            // Lexer error cannot go on
            return;
        }

        // Detect token where cursor resides
        for (let t = 0; t < lexResult.tokens.length; t++) {
            if (lexResult.tokens[t].endOffset === cursorpos - 2 && querytext[cursorpos - 1] === " ") {
                // Simply insert between two whitespaces or at end of line
                querytext = querytext.substring(0, cursorpos) + field + querytext.substring(cursorpos);
                return [querytext, cursorpos + field.length + cursorOffset];
            }
            if (lexResult.tokens[t].endOffset === cursorpos - 1 && lexResult.tokens[t].tokenType.name === "opNotToken") {
                // After "!" add not replace
                querytext = querytext.substring(0, cursorpos) + field + querytext.substring(cursorpos);
                return [querytext, cursorpos + field.length + cursorOffset];
            }
            if (lexResult.tokens[t].endOffset === cursorpos - 1) {
                let token = lexResult.tokens[t];

                // Replace current token
                querytext = querytext.substring(0, token.startOffset) + field + querytext.substring(cursorpos);
                return [querytext, token.startOffset + field.length + cursorOffset];
            }
        }

        return [querytext, cursorpos];
    }
};
