import API from "./api";

const cache = new Map();

export default {
    get: function(url) {
        const key = url;

        if (cache.has(key)) {
            return cache.get(key);
        } else {
            const request = API.get(url);
            cache.set(key, request);
            return request;
        }
    }
};
