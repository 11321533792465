<template>
    <query-assist :value="querytext" @input="updateData($event)" :condition-fields="theFields" :condition-flags="theFlags" />
</template>

<script>
import QueryAssist from "./QueryAssist";
import GetCache from "../../service/getCache";

export default {
    props: {
        value: {
            type: String,
            required: true
        },
        product: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            querytext: this.value,
            theFields: {},
            theFlags: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            GetCache.get("/docs/product/" + this.product + "/variables").then(
                response => {
                    this.theFields = {};
                    this.theFlags = [];

                    for (let f = 0; f < response.data.data.text.length; f++) {
                        let group = response.data.data.text[f];
                        let fields = [];

                        for (let fi = 0; fi < group.fields.length; fi++) {
                            fields.push({
                                id: group.id + ":" + group.fields[fi].id,
                                label: group.fields[fi].label
                            });
                        }

                        this.theFields[group.id] = {
                            name: group.id,
                            label: group.label,
                            fields: fields
                        };
                    }
                    for (let f = 0; f < response.data.data.flags.length; f++) {
                        this.theFlags.push({
                            id: response.data.data.flags[f].id,
                            label: response.data.data.flags[f].label
                        });
                    }
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Produktvariablen");
                }
            );
        },
        updateData(val) {
            this.querytext = val;
            this.$emit("input", this.querytext);
        }
    },
    components: {
        QueryAssist
    },
    watch: {
        value: function(val) {
            this.querytext = val;
        }
    }
};
</script>
