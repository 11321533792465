<template>
    <div class="form-group form-float with-icon no-error">
        <input ref="myinput" type="text" id="parser" v-model="querytext" @input="updateData()" @keyup="suggest($event)" placeholder=" " @blur="unfocus()" @click="suggest()" />
        <label for="parser">Condition</label>
        <i @click="doSearch()" class="fal" :class="{ 'fa-check': valid, 'fa-exclamation': !valid, valid: valid, invalid: !valid }"></i>
        <ul v-show="Object.keys(suggestions).length > 0" @mousedown.prevent.stop="" class="autocomplete">
            <template v-for="group in suggestions">
                <li class="group" v-bind:key="group.name">
                    <span>{{ group.label }}</span>
                    <ul>
                        <li
                            @mouseenter="unselect()"
                            :class="{ active: selected === field.idx }"
                            v-for="field in group.fields"
                            v-bind:key="field.field.id"
                            @mousedown.prevent.stop="select(field.field.id)"
                        >
                            {{ field.field.label }}<span v-show="field.field.label != field.field.id" class="small">- {{ field.field.id }} -</span>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import Vue from "vue";
import Parser from "../../service/lexer";

export default {
    data() {
        return {
            valid: true,
            querytext: this.value,
            selected: -1,

            suggestions: {},
            suggestionsCount: 0
        };
    },
    props: {
        value: {
            type: String,
            required: true
        },
        conditionFields: {
            type: Object,
            required: true
        },
        conditionFlags: {
            type: Array,
            require: true
        }
    },
    methods: {
        checkValid(fields, flags) {
            this.valid = Parser.checkValid(this.querytext, fields, flags);
        },
        updateData() {
            this.$emit("input", this.querytext);
        },
        unselect() {
            this.selected = -1;
        },
        unfocus() {
            this.suggestions = {};
            this.suggestionsCount = 0;
            this.unselect();
        },
        setCursorPos(caretPos) {
            let self = this;
            Vue.nextTick(function() {
                let elem = self.$refs.myinput;
                elem.setSelectionRange(caretPos, caretPos);

                self.suggest();
            });
        },
        select(field) {
            this.unfocus();

            let cursorpos = this.$refs.myinput.selectionStart;

            let [text, pos] = Parser.selectField(this.querytext, field, cursorpos);
            this.querytext = text;
            this.setCursorPos(pos);

            this.updateData();
        },
        suggest(event) {
            if (event !== undefined) {
                let keyCode = event.which || event.keyCode;
                if (keyCode !== undefined && (keyCode === 40 || keyCode === 38 || keyCode === 13)) {
                    if (keyCode === 38 && this.selected > 0) {
                        // Go down
                        this.selected--;
                    } else if (keyCode === 40 && this.selected < this.suggestionsCount - 1) {
                        // Go up
                        this.selected++;
                    } else if (keyCode === 13) {
                        // Select
                        for (let gid in this.suggestions) {
                            for (let f = 0; f < this.suggestions[gid].fields.length; f++) {
                                if (this.suggestions[gid].fields[f].idx === this.selected) {
                                    this.select(this.suggestions[gid].fields[f].field);
                                    return;
                                }
                            }
                        }
                    }

                    return;
                }
            }

            this.unfocus();

            // Do not suggest anything if something is selected
            if (this.$refs.myinput.selectionStart !== this.$refs.myinput.selectionEnd) {
                return;
            }

            let cursorpos = this.$refs.myinput.selectionStart;
            this.checkValid(this.conditionFields, this.conditionFlags);
            var suggestions = Parser.getContentAssistSuggestions(cursorpos, this.querytext, this.conditionFields, this.conditionFlags);

            // Assign numerical ids to fields
            let i = 0;
            for (let gid in suggestions) {
                for (let f = 0; f < suggestions[gid].fields.length; f++) {
                    suggestions[gid].fields[f] = {
                        field: suggestions[gid].fields[f],
                        idx: i
                    };
                    i++;
                }
            }

            this.suggestionsCount = i;
            this.suggestions = suggestions;
        }
    },
    mounted() {
        this.checkValid(this.conditionFields, this.conditionFlags);
    },
    watch: {
        value: function(val) {
            this.querytext = val;
            this.checkValid(this.conditionFields, this.conditionFlags);
        },
        conditionFields: function() {
            this.checkValid(this.conditionFields, this.conditionFlags);
        },
        conditionFlags: function() {
            this.checkValid(this.conditionFields, this.conditionFlags);
        }
    }
};
</script>

<style lang="scss" scoped>
ul.autocomplete {
    font-size: 0.8rem;
    background-color: #ffffff;
    list-style-type: none;
    margin: 0;
    padding: 0 0 0.7rem 0;
    position: absolute;
    left: 2rem;
    top: 2.4rem;
    max-height: 20rem;
    overflow-y: auto;
    z-index: 1001;

    box-shadow: 0 2px 16px 0 rgba(0, 42, 76, 0.15);

    li.group {
        margin-top: 0.7rem;
        border-top: 1px solid #bbbbbb;
        &:first-child {
            border-top: 0px;
        }
        > span {
            color: lighten(#8d9498, 20%);
            display: block;
            text-align: right;
            padding: 0 1rem 0 1rem;
            line-height: 1.5rem;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                cursor: pointer;
                padding: 0.3rem 3rem 0.3rem 1rem;

                &:hover,
                &.active {
                    background-color: #0066b3;
                    color: #ffffff;
                }
            }
        }
    }
}

i.valid {
    background-color: #4caf50;
    color: #ffffff;
}

i.invalid {
    background-color: #e84740;
    color: #ffffff;
}

span.small {
    font-size: 0.6rem;
    margin-left: 0.5rem;
}
</style>
